import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

export default function AboutUs({ data, location }) {
  const pageData = data.aboutUs;
  const jobPostings = data.jobPostings.edges;

  return (
    <Layout bodyClass="page-blog">
      <SEO
        title={pageData.frontmatter.title}
        image={null}
        url={location.href}
      />
      <div className="container pt-6">
        <div className="col-12 d-flex justify-content-between">
          <h1>{pageData.frontmatter.title}</h1>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="col-12">
          <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
        </div>
      </div>

      {pageData.frontmatter.aboutUs_image && (
        <div className="container">
          <div className="col-12 mb-8 mt-8">
            <div className="row justify-content-around">
              <img
                alt={pageData.frontmatter.title}
                style={{ width: '100%' }}
                src={pageData.frontmatter.aboutUs_image}
              />
            </div>
          </div>
        </div>
      )}
      {/* TODO Rename/copy css classes */}
      {jobPostings.length > 0 && (
        <div className="strip">
          <div className="container">
            <div className="col-12">
              <div className="row">
                <h3>
                  Du begeisterst dich für Softwareentwicklung, hast Erfahrung
                  mit Microsoft Technologien und suchst nach einer neuen
                  Herausforderung? Sieh dir unsere offenen Positionen an!
                </h3>
              </div>
            </div>
            <div className="row justify-content-start pt-2">
              {jobPostings.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6">
                  <div className="service service-summary">
                    <div className="service-content">
                      <h2 className="service-title">
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title_short}
                        </Link>
                      </h2>
                      <p>
                        {node.frontmatter.teaser_text}{' '}
                        <Link to={node.fields.slug}>Mehr</Link>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    aboutUs: markdownRemark(
      fileAbsolutePath: { regex: "/content/about-us.md/" }
    ) {
      html
      frontmatter {
        title
        aboutUs_image
      }
      excerpt
    }
    jobPostings: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/careers/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            title_short
            teaser_text
            date
          }
        }
      }
    }
  }
`;
